@import url("https://fonts.googleapis.com/css2?family=Comforter+Brush&family=Qwitcher+Grypen:wght@700&family=Rock+3D&family=Shizuru&display=swap");

$mobile-breakpoint: 768px;

////////////
// COLORS //
////////////
$primary: #00888a;
$red: #ff0000;
$backside-text: #2671a5;

* {
  box-sizing: border-box;
  -webkit-print-color-adjust: exact; /* Needed to output colors to Chrome print PDF */
}

body {
  overflow-x: hidden;
  transform: rotate3d(0, 0, 0);
}

.background-blue {
  height: 100vh;
  background-color: #e2e8ff;
}

.btn,
button {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  background-color: white;
  border: 1px solid transparent;
  color: $primary;
  border-color: $primary;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
  }
  &:active {
    box-shadow: 0 0 0 0.2rem rgb(0 136 138 / 50%);
  }
  &:disabled,
  &:disabled:hover,
  :disabled:active {
    cursor: not-allowed;
    background-color: #efefef;
    color: #6a6a6a;
    border: 1px solid #6a6a6a;
  }
  &.btn-warning {
    color: $red;
    border: 1px solid $red;
    &:hover {
      background-color: rgb(255, 209, 209);
    }
  }
}

.play {
  overflow-x: hidden;
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .picture-card-title {
    font-size: 25px;
    font-weight: 600;
    text-align: center;
  }

  .picture-card {
    margin: 0 auto;
    margin-top: 10px;
    padding: 0;
  }

  .flip-cart-button {
    @media (min-width: $mobile-breakpoint) {
      margin-top: 10px;
    }
    font-size: 25px;
    font-weight: bold;
  }

  .bottom-buttons {
    margin-top: 40px;
    display: flex;
    align-items: center;
  }
}

.picture-sheet {
  height: 1122.52px; /* 29.7 cm */
  width: 793.71px; /* 21 cm */

  .picture-card {
    display: inline-block;
    .backside {
      position: relative;
    }
    .backside.visible {
      display: none;
    }
    .frontside.visible {
      display: none;
    }
    .picture-line {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

@supports not (-webkit-touch-callout: none) {
  .swiper.is-shaking {
    .backside,
    .frontside {
      animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      perspective: 1000px;
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(3px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-6px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(6px, 0, 0);
  }
}

.picture-card {
  padding: 15px;
  padding-top: 25px;
  width: 264.55px; /* 7 ( 21.0 / 3) cm */
  height: 369.2px; /* slightly less than 9.6 ( = 29.7 / 3 ) cm */
  position: relative;
  text-transform: uppercase;
  transition: all 0.07s linear;

  h2 {
    margin-top: 0;
    text-align: center;
  }

  a {
    text-decoration: none;
    color: black !important;
    .yellow:hover {
      background-color: #e0dc00;
      color: $red;
    }
    .blue:hover {
      background-color: #1890e2;
      color: white;
    }
    .brown:hover {
      background-color: #c08407;
      color: white;
    }
    .green:hover {
      background-color: green;
      color: white;
    }
    .red:hover {
      background-color: #de3c25;
      color: white;
    }
  }

  .backside,
  .frontside {
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    transition: all 0.2s;
  }

  .backside {
    border: 10px solid $red;
    font-family: "Shizuru", cursive;
    z-index: 1;
    color: #fdf92a;
    background-color: #72bcef;

    .i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -63%);
      font-size: 230px;
      text-shadow: 2px 2px 0 $backside-text, 4px 4px 0 $backside-text,
        -4px 4px 0 $backside-text, -4px -4px 0 $backside-text,
        0px 4px 0 $backside-text, 0px -4px 0 $backside-text,
        -4px 0px 0 $backside-text, 4px 0px 0 $backside-text,
        2px 4px 0 $backside-text, -2px 4px 0 $backside-text,
        2px -4px 0 $backside-text, -2px -4px 0 $backside-text,
        4px 2px 0 $backside-text, -4px 2px 0 $backside-text,
        4px -2px 0 $backside-text, -4px -2px 0 $backside-text;
    }

    .maginary {
      position: absolute;
      left: 50%;
      top: 81%;
      transform: translate(-50%, 0%);
      font-size: 22px;
    }
  }
}

.swiper-slide-active {
  .backside.is-turning {
    transform: rotateY(90deg);
  }
  .frontside.is-turning {
    transform: rotateY(-90deg);
  }
}

.picture-line {
  text-decoration: none;
  border: 1px solid black;
  font-weight: bold;
  margin-bottom: 15px;
  margin-right: 15px;
  margin-left: 15px;

  .letter {
    padding: 8px;
    display: inline-block;
    width: 30px;
    border-right: 1px solid rgb(133, 119, 119);
    text-align: center;
  }

  .arrow {
    display: inline-block;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 13px solid black;
    border-bottom: 7px solid transparent;
    padding-right: 6px;
    vertical-align: top;
    padding-top: 1px;
    margin-top: 12px;
    @media (max-width: $mobile-breakpoint) {
      margin-top: 10px;
    }
  }
  &:hover .arrow {
    border-left: 13px solid $red;
  }

  &.brown {
    background-color: #f6c55e;
  }

  &.yellow {
    background-color: #fdf92a;
  }

  &.blue {
    background-color: #72bcef;
  }

  &.green {
    background-color: #32bd7a;
  }

  &.red {
    background-color: #f06f5d;
    margin-bottom: 0;
  }
}

.timer-digit {
  font-size: 22px;
  font-weight: bold;
  position: absolute;
  top: 80px;
  left: 16px;
  color: black;
  height: 40px;
  width: 30px;
  z-index: 4;

  &.time-is-up {
    position: fixed;
    left: 5vw;
    top: 5vh;
    height: 40vh;
    width: 90vw;
    font-size: 50px;
    color: red;
    padding: 20px;
    background-color: white;
    border: 1px solid red;
  }

  &.time-is-up.on {
    background-color: red;
    color: white;
  }
}

.timer-background,
.timer-triangles {
  position: absolute;
  top: 0;
  left: 10px;
  content: "";
  height: 80px;
  width: 40px;
}

.timer-background {
  background-color: rgb(255, 220, 220);
  z-index: 1;

  &:after {
    position: absolute;
    bottom: 0;
    content: "";
    width: 40px;
    background-color: red;
    z-index: 4;
  }
}

.timer-triangles {
  z-index: 2;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: "";
    height: 0;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    z-index: 3;
  }

  &:after {
    left: -1px;
    border-left: 18px solid white;
  }

  &:before {
    right: -1px;
    border-right: 18px solid white;
  }
}

//////////////
// REUSABLE //
//////////////

.d-none {
  display: none;
}

.d-inline {
  display: inline;
}

.container {
  margin: 20px;
}

////////////
// SWIPER //
////////////

.swiper-button-prev {
  left: 30px !important;
  &:after {
    content: "◀" !important;
  }
}

.swiper-button-next {
  right: 30px !important;
  &:after {
    content: "▶" !important;
  }
}

.rules {
  h2 {
    margin-bottom: 0;
    text-transform: uppercase;
  }
  p {
    margin-top: 10px;
  }
}
